import { genMenuItemCheck } from '@/utils/list-generators';
import { computed, del, ref, set, watch } from '@vue/composition-api';

const FILTER_TYPES = {
  NO_FILTER: 'NO_FILTER',
  DISABLED: 'DISABLED'
};

const defaultFilter = {
  [FILTER_TYPES.NO_FILTER]: true
};

const GEOZONE_LIST_FILTER = 'GEOZONE_LIST_FILTER';

export function useObjectFilterMenu() {
  const savedFilter =
    JSON.parse(localStorage.getItem(GEOZONE_LIST_FILTER)) || defaultFilter;

  const filter = ref(savedFilter);

  watch(
    () => filter.value,
    () => {
      localStorage.setItem(GEOZONE_LIST_FILTER, JSON.stringify(filter.value));
    }
  );

  const filters = [
    // {
    //   id: FILTER_TYPES.NO_FILTER,
    //   title: 'No filter',
    //   filter: () => true
    // },
    // {
    //   id: FILTER_TYPES.DISABLED,
    //   title: 'Disabled',
    //   filter: item => !item.enabled
    // }
  ];

  const menuItems = computed(() =>
    filters.map(i =>
      genMenuItemCheck({
        ...i,
        checked: !!filter.value[i.id]
      })
    )
  );

  const handleFilter = item => {
    if (item.id === FILTER_TYPES.NO_FILTER) {
      filter.value = {
        [FILTER_TYPES.NO_FILTER]: true
      };
      return;
    }
    if (filter.value[item.id]) {
      del(filter.value, item.id);
    } else {
      set(filter.value, item.id, true);
    }
    del(filter.value, FILTER_TYPES.NO_FILTER);
  };

  const filterFunctions = computed(() =>
    filters
      .filter(i => Object.keys(filter.value).includes(i.id))
      .map(i => i.filter)
  );

  return {
    menuItems,
    filterFunctions,
    handleFilter
  };
}
