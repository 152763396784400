import { apiClient } from '@/provider';
import { prepareSchema } from '@/provider/utils';
import stateScheme from './state.graphql';

const SCHEME_NAME_PREFIX = 'PixelMonitor Object State Item, ';
const addNamePrefix = name => `${SCHEME_NAME_PREFIX}${name}`;

export default {
  fetchBaseStateSchemaId() {
    return apiClient
      .query({
        query: stateScheme.fetchBaseStateSchemaId
      })
      .then(r => r.data.schemata[0]?.id || '');
  },
  async fetch(id) {
    const res = await apiClient.query({
      query: stateScheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    });
    return prepareSchema(res.data.schema);
  },
  create({ name, schemaId, parentSchemaId }) {
    return apiClient
      .mutate({
        mutation: stateScheme.create,
        variables: {
          input: {
            newSchemaName: addNamePrefix(name),
            schemaId,
            newSchemaTags: ['application', 'monitor', 'object state'],
            linkToSchemaId: parentSchemaId
          }
        }
      })
      .then(r => r.data?.createSchemaCopy?.uuid);
  },

  update(id, { name, description }) {
    return apiClient.mutate({
      mutation: stateScheme.update,
      variables: {
        id,
        name: addNamePrefix(name),
        description
      }
    });
  }
};
