import geozoneSchema from '../api/geozone.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { prepareObject } from '@/provider/utils';
import { mediaClient } from '@/provider';

export function useGeozone(geozoneId) {
  const { result, load, loading } = useLazyQuery(
    geozoneSchema.fetch,
    {
      id: geozoneId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const item = useResult(result, {}, data => {
    const preparedObject = prepareObject(data.object);
    return {
      ...preparedObject,
      color: preparedObject.infoColor.value,
      points: preparedObject.positionPoints.value,
      url: mediaClient.getImageUrl(preparedObject.infoImageId.value)
    };
  });

  return {
    load,
    item,
    loading
  };
}
