<template>
  <simple-card :title="title" @click:back="handleBack">
    <base-list :items="items" />
    <v-list>
      <v-list-item>
        <v-btn outlined block @click="handleSave">Save</v-btn>
      </v-list-item>
    </v-list>
  </simple-card>
</template>

<script>
import SimpleCard from '@/components/simple-card';
import { useObject } from '@/modules/objects/compositions/object';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from 'vue-demi';
import { genDefaultItem } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';
import { usePopup } from '@/compositions/popup';
import { onBeforeRouteLeave } from '@/compositions/router';
import { throttle } from 'throttle-debounce';
import { objectPropertyService } from '@/modules/common/api';
import {formatCoordinates, getEmptyString} from '@/utils';
import { useObjects } from '@/modules/objects/compositions/objects';
import ObjectsService from '@/modules/objects/api/object-list';
import {useGeotags} from "@/modules/geotags/compositions/geotags";

export default {
  name: 'ObjectMoving',
  components: {
    SimpleCard
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    objectIds: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { root }) {
    const innerObjectIds = computed(() => [
      ...(props.objectId ? [props.objectId] : []),
      ...props.objectIds
    ]);
    const state = ref({
      name: '',
      altitude: 0,
      lat: 0,
      lon: 0,
      geotag: ''
    });
    const edited = ref(false);
    const { item: entity, load } = useObject(innerObjectIds.value[0]);
    const { listMap } = useObjects();
    const { getGeotagById } = useGeotags();
    const {
      markers: {
        zoomInAndSelectMarker,
        updateMarker,
        editMarkerState,
        enableEditMarker,
        disableEditMarker
      }
    } = useMap();
    const popup = usePopup();

    onBeforeMount(() => {
      if (!innerObjectIds.value.length) {
        root.$router.replace({ name: 'objects' });
      }
    });

    onBeforeRouteLeave((to, from, next) => {
      if (edited.value) {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Save new geometry of the object?',
            onCancel: () => {
              setTimeout(() => next());
            },
            onConfirm: async () => {
              await handleSave();
              next();
            }
          }
        });
      } else {
        next();
      }
    });

    const handleBack = () => {
      const { path } = root.$route;
      const newPath = path
        .replace(/\/$/, '')
        .split('/')
        .slice(0, -1)
        .join('/');
      root.$router.push(newPath);
    };

    const handleSave = async () => {
      disableEditMarker(innerObjectIds.value);
      const objects = await ObjectsService.fetchMany(innerObjectIds.value);
      const payload = objects.map(object => {
        const markerState = editMarkerState.value[object.id];
        return {
          id: object.positionPoint.id,
          value: {
            lat: Number(markerState.lat),
            lon: Number(markerState.lon),
            alt: Number(state.value.altitude)
          }
        };
      });
      await objectPropertyService.updateValues(payload);
    };

    const getLabel = label => {
      return innerObjectIds.value.length > 1 ? 'N/A' : label;
    };

    const items = computed(() => [
      genDefaultItem({
        title: 'Object name',
        subTitle: getLabel(state.value.name),
        icon: '$object',
        invert: true
      }),
      genDefaultItem({
        title: 'Center',
        subTitle: getLabel(
          formatCoordinates({
            lon: state.value.lon,
            lat: state.value.lat
          })
        ),
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        title: 'Altitude',
        subTitle: getLabel(state.value.altitude),
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        title: 'Geotag',
        subTitle: getEmptyString(getGeotagById(state.value.geotag)?.name),
        icon: '$geotag',
        invert: true
      })
    ]);

    const title = computed(() => {
      return `Moving ${innerObjectIds.value.length} object(s)`;
    });

    onMounted(() => {
      load();
    });

    onBeforeUnmount(() => {
      disableEditMarker(innerObjectIds.value);
    });

    const throttleUpdate = throttle(500, value => {
      state.value = {
        ...state.value,
        ...value
      };
    });

    watch(
      () => entity.value,
      value => {
        state.value = {
          name: value.name,
          geotag: value.positionGeotagId?.value,
          altitude: value.positionPoint?.value.alt || 0,
          lat: value.positionPoint?.value.lat,
          lon: value.positionPoint?.value.lon
        };
        updateMarker(value);
        if (innerObjectIds.value[0]) {
          zoomInAndSelectMarker(innerObjectIds.value[0]);
        }
        enableEditMarker(innerObjectIds.value);
        edited.value = false;
      }
    );

    watch(
      () => editMarkerState.value,
      value => {
        throttleUpdate(value);
        edited.value = true;
      }
    );

    return {
      handleBack,
      items,
      handleSave,
      entity,
      editMarkerState,
      state,
      title,
      listMap,
      innerObjectIds
    };
  }
};
</script>

<style></style>
