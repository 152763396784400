import { apiClient } from '@/provider';
import { prepareObject } from '@/provider/utils';
import objectScheme from './object-list.graphql';
export default {
  async fetchMany(ids) {
    const res = await apiClient.query({
      query: objectScheme.load,
      variables: {
        ids
      },
      fetchPolicy: 'network-only'
    });
    return res.data.objects.map(object => prepareObject(object));
  }
};
