<template>
  <v-toolbar flat class="fill-height">
    <v-btn icon @click="$emit('click:back')">
      <v-icon color="primary" v-text="'$arrow_left'" />
    </v-btn>
    <v-toolbar-title class="text-h6 primary--text" v-text="title" />
    <v-spacer />

    <slot name="extra-items" />

    <dropdown-menu
      v-if="actions.length > 0"
      item-key-text="name"
      :items="actions"
      offset-y
      @input="$emit('click:action', $event)"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon
            v-if="actions.length > 0"
            color="primary"
            v-text="'$more_vert'"
          />
        </v-btn>
      </template>
    </dropdown-menu>
    <dropdown-menu-selector
      v-if="sorting.length > 0"
      item-key-text="name"
      :items="sorting"
      :value="currentSortBy"
      activator-icon="$sort"
      offset-y
      @input="$emit('click:sort', $event)"
    />
  </v-toolbar>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  name: 'ToolBarSelection',
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Array,
      default: () => []
    },
    currentSortBy: {
      type: String,
      default: 'default'
    },
    selectedCount: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    return {
      title: computed(() => `Selected: ${props.selectedCount}`)
    };
  }
};
</script>

<style></style>
