import { usePopup } from '@/compositions/popup';
import { genMenuItemAdd } from '@/utils/list-generators';

export function useTypeListActions() {
  const popup = usePopup();

  return {
    listActions: [
      genMenuItemAdd(() => {
        popup.open({
          component: () => import('@/modules/object-types/ui/TypeEdit.vue')
        });
      })
    ]
  };
}
