<template>
  <v-menu
    v-model="showPopup"
    offset-y
    :nudge-bottom="12"
    :nudge-left="385"
    :close-on-content-click="false"
    max-width="412"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!$vuetify.breakpoint.mobile"
        fab
        small
        color="white"
        class="mr-3"
        v-on="on"
        @click="onClick"
      >
        <v-badge color="red" dot overlap :value="hasNew">
          <v-icon v-text="'$alert'" />
        </v-badge>
      </v-btn>
      <v-badge v-else color="red" dot overlap :value="hasNew">
        <v-icon v-on="on" @click="onClick" v-text="'$alert'" />
      </v-badge>
    </template>
    <v-sheet max-width="412" min-width="412">
      <notification-list
        :notifications="listItems"
        @infinite="loadMore"
        @close="showPopup = false"
      />
    </v-sheet>
  </v-menu>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

import { useNotificationsCurrentUser } from '@/modules/notifications/compositions/notificationsCurrentUser';

import NotificationList from '@/layouts/map/headerMenu/notifications/NotificationList';

export default {
  name: 'Notifications',
  components: {
    NotificationList
  },
  setup(props, { root }) {
    const { listItems, loadMore, hasNew } = useNotificationsCurrentUser();

    const showPopup = ref(false);
    const formattedNotifications = ref([]);
    const isMobile = computed(() => root.$vuetify.breakpoint.smAndDown);

    const onClick = () => {
      hasNew.value = false;
      showPopup.value = true;
    };

    return {
      formattedNotifications,
      hasNew,
      isMobile,
      showPopup,
      loadMore,
      listItems,
      onClick
    };
  }
};
</script>
