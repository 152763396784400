<template>
  <div class="d-flex flex-column">
    <v-tabs v-model="tab" grow class="flex-grow-0">
      <v-tab v-for="(type, index) in types" :key="index" class="pa-0">
        <span v-if="type === 'all'">{{ type }}</span>
        <v-icon v-else>{{ getTypeIcon(type) }}</v-icon>
      </v-tab>
    </v-tabs>

    <v-divider />
    <div style="max-height: 400px; overflow-y: auto">
      <v-list class="align-start flex-grow-1" three-line>
        <component
          :is="item.type"
          v-for="(item, index) in filteredNotifications"
          :key="index"
          v-bind="item.props"
          class="list-item"
          v-on="item.listeners"
        />
      </v-list>
      <infinite-loading class=" mb-5" @infinite="$emit('infinite', $event)">
        <div slot="spinner">
          <v-progress-circular
            class="mt-2"
            size="25"
            width="2"
            indeterminate
            color="primary"
          />
        </div>
        <div slot="no-more" />

        <div slot="no-results">No more notifications found</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'NotificationList',
  components: {
    InfiniteLoading
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const types = ['all', '$alert_on', '$notice', '$message'];
    const tab = ref(0);
    function getTypeIcon(v) {
      switch (v) {
        case '$alert_on':
          return 'mdi-bell-ring';
        case '$notice':
          return 'mdi-volume-high';
        case '$message':
          return 'mdi-message-text';
      }
    }

    const filteredNotifications = computed(() => {
      if (tab.value === 0) {
        return props.notifications;
      }
      return props.notifications.filter(
        item => item.props.icon === types[tab.value]
      );
    });

    return {
      getTypeIcon,
      types,
      filteredNotifications,
      tab
    };
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  ::v-deep .v-list-item__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
