import { usePopup } from '@/compositions/popup';
import { genMenuItemAdd } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';

export function useObjectListActions() {
  const popup = usePopup();
  const {
    geozones: { getDefaultSize }
  } = useMap();

  return {
    listActions: [
      genMenuItemAdd(() => {
        popup.open({
          component: () => import('@/modules/geozones/ui/GeozoneEdit.vue'),
          props: {
            defaultSize: getDefaultSize()
          }
        });
      })
    ]
  };
}
