import { usePopup } from '@/compositions/popup';
import { genMenuItemAdd } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';

export function useObjectListActions() {
  const popup = usePopup();

  return {
    listActions: [
      // genMenuItemAdd(() => {
      //   popup.open({
      //     component: () => import('@/modules/connections/ui/ConnectionEdit.vue')
      //   });
      // })
    ]
  };
}
