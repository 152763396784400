<template>
  <history-stack
    :default-view-component="() => import('./TypeCard.vue')"
    v-bind="$attrs"
    @stack:close="handleClose"
  />
</template>

<script>
export default {
  name: 'TypeHistoryStack',
  setup(props, { root }) {
    const handleClose = () => {
      root.$router.push({
        name: 'types'
      });
    };
    return {
      handleClose
    };
  }
};
</script>
