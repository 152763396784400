import { objectService } from '@/modules/common/api';
import { apiClient } from '@/provider';
import linkedObjectSchema from './linked-object.graphql';

export default {
  ...objectService,
  async fetchControlsByObjectId(linkedObjectId) {
    const res = await apiClient.query({
      query: linkedObjectSchema.fetchControlsByObjectId,
      variables: {
        id: linkedObjectId
      },
      fetchPolicy: 'no-cache'
    });

    return res.data?.object;
  },
  async createControlExecution(objectId, name, params) {
    return apiClient.mutate({
      mutation: linkedObjectSchema.createControlExecution,
      variables: {
        input: {
          objectId,
          name,
          params
        }
      }
    });
  },
  update(id, { name, description, enabled }) {
    return apiClient.mutate({
      mutation: linkedObjectSchema.update,
      variables: {
        id,
        name,
        description,
        enabled
      }
    });
  }
};
