import { ColorNames, colors } from '@/compositions/map/utils/data';

export const DEFAULT_FILTER_TYPES = {
  STATUS: 'STATUS',
  COLORS: 'COLORS',
  FAVOURITES: 'FAVOURITES',
  MUTED: 'MUTED'
};

export const getDefaultFilter = filters =>
  filters.reduce((acc, filter) => {
    if (filter.id) {
      acc[filter.id] = filter.default;
    }
    return acc;
  }, {});

export const defaultFilters = {
  [DEFAULT_FILTER_TYPES.STATUS]: {
    id: DEFAULT_FILTER_TYPES.STATUS,
    default: null,
    component: {
      name: 'select',
      label: 'Status',
      items: [
        {
          label: 'All',
          value: null
        },
        {
          label: 'Enable',
          value: true
        },
        {
          label: 'Disable',
          value: false
        }
      ]
    },
    filterFunc: (item, value) => item.enabled === value || value === null
  },
  [DEFAULT_FILTER_TYPES.COLORS]: {
    id: DEFAULT_FILTER_TYPES.COLORS,
    default: [],
    component: {
      name: 'tagSelect',
      colorChips: true,
      label: 'Colors',
      items: Object.keys(colors).map(key => ({ name: key, value: key }))
    },
    filterFunc: (item, valueArray) =>
      valueArray
        ?.map(v => v.value)
        .some(color =>
          [(item.color || ColorNames.default).toLowerCase()].includes(color)
        ) || !valueArray.length
  },
  [DEFAULT_FILTER_TYPES.FAVOURITES]: {
    id: DEFAULT_FILTER_TYPES.FAVOURITES,
    default: null,
    component: {
      name: 'select',
      label: 'Favourites',
      items: [
        {
          label: 'All',
          value: null
        },
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ]
    },
    filterFunc: (item, value) => item.favourite === value || value === null
  },
  [DEFAULT_FILTER_TYPES.MUTED]: {
    id: DEFAULT_FILTER_TYPES.MUTED,
    default: null,
    component: {
      name: 'select',
      label: 'Muted',
      items: [
        {
          label: 'All',
          value: null
        },
        {
          label: 'Yes',
          value: true
        },
        {
          label: 'No',
          value: false
        }
      ]
    },
    filterFunc: (item, value) => item.muted === value || value === null
  }
};
