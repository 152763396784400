import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit } from '@/utils/list-generators';

export function useDefaultItemMenu() {
  const popup = usePopup();

  return {
    genMenu: (property, callback) => {
      const { value } = property || {};
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/PopupValueEdit.vue'),
            props: {
              valueName: 'Value',
              confirmText: 'Ok',
              value,
              onConfirm: async value => callback(value)
            }
          });
        })
      ];
    }
  };
}
