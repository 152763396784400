import { computed } from '@vue/composition-api';

export function useFilterBy(items, filterFunctions) {
  const filteredItems = computed(() => {
    if (!filterFunctions?.value?.length) return items.value;

    return filterFunctions.value.reduce((acc, filterFunc) => {
      if (typeof filterFunc !== 'function') {
        return acc;
      }
      return acc.filter(filterFunc);
    }, items.value);
  });

  return {
    filteredItems
  };
}
