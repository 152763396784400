import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import router from '@/router';

import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDefault,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable,
  genMenuItemShowHistory,
  genMenuItemMove
} from '@/utils/list-generators';
import { geozoneService } from '../api';

export function useGeozoneMenu({ entity }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { id, enabled } = entity;

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () => import('@/modules/geozones/ui/GeozoneEdit.vue'),
        props: {
          geozoneId: id
        }
      });
    }),
    genMenuItemAccess(() => {
      popup.open({
        component: () => import('@/components/popup/EditRightsGroup.vue'),
        props: {
          objectId: id
        }
      });
    }),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable geozone?',
              onConfirm: () => geozoneService.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable geozone?',
              onConfirm: () => geozoneService.enable(id)
            }
          });
        }),
    genMenuItemMove(() => {
      router.push({
        name: 'geozone_moving',
        params: {
          geozoneId: id
        }
      });
    }),
    genMenuItemDefault({
      title: 'Set coordinates sources',
      icon: '$marker_set',
      disabled: true
    }),
    genMenuItemShowHistory(undefined, { disabled: true }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(() => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title: 'Delete geozone?',
          onConfirm: async () => {
            geozoneService.delete(id);
            router.push({
              name: 'geozones'
            });
          }
        }
      });
    })
  ];
}
