import { apiClient } from '@/provider';
import { prepareObject } from '@/provider/utils';
import connectionMessageTypeScheme from './connection-message-type-list.graphql';

export default {
  async fetch(objectId) {
    const res = await apiClient.query({
      query: connectionMessageTypeScheme.load,
      variables: {
        objectId
      },
      fetchPolicy: 'no-cache'
    });
    return res.data.objectsToObjects.map(i => prepareObject(i.object2));
  }
};
