import { ref, watch } from '@vue/composition-api';

const isMapState = ref(true);
export function useToggleView(vuetify) {
  const toggle = () => {
    isMapState.value = !isMapState.value;
  };

  watch(
    () => vuetify.breakpoint?.mobile,
    value => {
      if (!value) {
        isMapState.value = false;
      }
    }
  );
  return {
    toggle,
    isMapState
  };
}
