<template>
  <history-stack
    :default-view-component="() => import('./PlanCard.vue')"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'PlanHistoryStack'
};
</script>
