import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import router from '@/router';

import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable,
  genMenuItemShowHistory,
  genMenuItemMove
} from '@/utils/list-generators';
import { planService } from '../api';

export function usePlanMenu({ entity }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { id, enabled } = entity;

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () => import('@/modules/plans/ui/PlanEdit.vue'),
        props: {
          planId: id
        }
      });
    }),
    genMenuItemCopy(() => {
      popup.open({
        component: () => import('@/modules/plans/ui/PlanEdit.vue'),
        props: {
          planId: id,
          createCopy: true
        }
      });
    }),
    genMenuItemAccess(() => {
      popup.open({
        component: () => import('@/components/popup/EditRightsGroup.vue'),
        props: {
          objectId: id
        }
      });
    }),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable plan?',
              onConfirm: () => planService.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable plan?',
              onConfirm: () => planService.enable(id)
            }
          });
        }),
    genMenuItemMove(() => {
      router.push({
        name: 'plan_moving',
        params: {
          planId: id
        }
      });
    }),
    genMenuItemShowHistory(undefined, { disabled: true }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(() => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title: 'Delete plan?',
          onConfirm: async () => {
            await planService.delete(id);
            router.push({
              name: 'plans'
            });
          }
        }
      });
    })
  ];
}
