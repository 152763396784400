<template>
  <list-card
    ref="refList"
    :items="items"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortBy"
    title="Landmarks"
    entity-type="landmark"
    @click:sort="handleSort"
    @click:filter-icon="handleFilterOpen"
    @selectedItemIds="handleSelectedItemIds"
    @active="handleActive"
  >
    <template #item="{select, ...props}">
      <landmark-list-item
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
      />
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import LandmarkListItem from './LandmarkListItem';
import { useObjectListActions } from './listActions';
import { linkedObjectService } from '@/modules/linked-objects/api';

import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';

import { useSortBy } from '@/compositions/sortBy';
import { useLandmarkMenu } from '@/modules/landmarks/compositions/landmarkMenu';
import { useLandmarks } from '@/modules/landmarks/compositions/landmarks';
import sortingMenu from './sortingMenu';
import { useMap } from '@/compositions/map';
import { genMenuItemDelete } from '@/utils/list-generators';
import { usePopup } from '@/compositions/popup';
import { useFilter } from '@/compositions/filter';
import {
  LANDMARK_LIST_FILTER,
  useLandmarkFilters
} from '@/modules/landmarks/compositions/landmark-filters';

export default {
  name: 'Landmarks',
  components: {
    ListCard,
    LandmarkListItem
  },

  setup(props, { root }) {
    const refList = ref(null);
    const popup = usePopup();
    const { list } = useLandmarks();
    const {
      landmarks,
      markers: { clearMarkers }
    } = useMap();

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const getItemRoute = item => ({
      name: 'landmark_card',
      params: {
        landmarkId: item.id
      },
      query: root.$route.query
    });

    const currentSortBy = ref(sortingMenu[0].id);

    const selectedIds = ref([]);

    const getItemActions = entity => {
      return useLandmarkMenu({ entity });
    };

    const keyword = computed(() => {
      return root.$route?.query?.keyword;
    });

    const searchedList = computed(() => {
      return keyword.value
        ? list.value.filter(i => i.title.includes(keyword.value))
        : list.value;
    });

    const filters = useLandmarkFilters();

    const {
      filteredItems,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    } = useFilter(searchedList, filters, LANDMARK_LIST_FILTER);

    const { sortType, sortKey, sortedItems } = useSortBy(filteredItems);

    const handleSort = e => {
      currentSortBy.value = e.id;
      sortType.value = e.sortType;
      sortKey.value = e.sortKey;
    };

    const { listActions } = useObjectListActions();

    const handleSelectedItemIds = ids => {
      selectedIds.value = ids;
      landmarks.selectLandmarks(ids);
    };

    const handleActive = ({ id, active }) => {
      active ? landmarks.selectLandmark(id) : landmarks.unselectLandmark(id);
    };

    watch(
      () => filteredItems.value,
      value => {
        landmarks.setLandmarks(value);
      },
      {
        immediate: true
      }
    );

    onMounted(() => {
      clearMarkers();
    });

    onBeforeUnmount(() => {
      landmarks.clearLandmarks();
    });

    const groupActions = [
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete these landmarks completely?',
            onConfirm: () => {
              linkedObjectService
                .massiveDelete(selectedIds.value.map(item => ({ id: item })))
                .then(() => {
                  clearMarkers();
                });
              clearSelection();
            }
          }
        });
      })
    ];

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      sorting: sortingMenu,
      currentSortBy,
      handleSort,
      handleSelectedItemIds,
      list,
      handleActive,
      refList,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    };
  }
};
</script>

<style></style>
