import planSchema from '../api/plan.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { prepareObject } from '@/provider/utils';
import { mediaClient } from '@/provider';

export function usePlan(planId) {
  console.log('usePlan');
  const { result, load, loading } = useLazyQuery(
    planSchema.fetch,
    {
      id: planId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const item = useResult(result, {}, data => {
    const preparedObject = prepareObject(data.object);
    return {
      ...preparedObject,
      url: mediaClient.getImageUrl(preparedObject.imageId.value)
    };
  });

  return {
    load,
    item,
    loading
  };
}
