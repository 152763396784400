import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import router from '@/router';

import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable
} from '@/utils/list-generators';
import { typeService } from '../api';

export function useTypeMenu({ entity }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { id, enabled, mTags } = entity;

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () => import('@/modules/object-types/ui/TypeEdit.vue'),
        props: {
          typeId: id
        }
      });
    }),
    genMenuItemAccess(
      () => {
        console.log('click on access');
      },
      {
        disabled: true
      }
    ),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable type?',
              onConfirm: () => typeService.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable type?',
              onConfirm: () => typeService.enable(id)
            }
          });
        }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(
      () => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete type?',
            message:
              'When you delete the type, all objects of this type will be also deleted. Delete this type?',
            onConfirm: async () => {
              await typeService.delete(id);
              router.push({
                name: 'types'
              });
            }
          }
        });
      },
      {
        disabled: mTags?.includes('generic')
      }
    )
  ];
}
