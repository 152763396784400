<template>
  <v-toolbar flat class="fill-height">
    <dropdown-menu
      item-key-text="name"
      :items="actions"
      offset-y
      @input="$emit('click:action', $event)"
    >
      <template v-slot:activator="{ on }">
        <v-btn text class="px-0" v-on="actions.length > 0 && on">
          <span class="text-capitalize primary--text text-h6">{{ title }}</span>
          <v-icon
            v-if="actions.length > 0"
            color="primary"
            v-text="'$menu_down'"
          />
        </v-btn>
      </template>
    </dropdown-menu>
    <v-spacer />
    <slot name="extra-items" />
    <v-btn icon @click="$emit('click:filter-icon')">
      <v-badge color="red" dot :value="isFilterActive">
        <v-icon color="primary" v-text="'$tune'" />
      </v-badge>
    </v-btn>

    <dropdown-menu-selector
      v-if="sorting.length > 0"
      item-key-text="name"
      :items="sorting"
      :value="currentSortBy"
      activator-icon="$sort"
      offset-y
      @input="$emit('click:sort', $event)"
    />
  </v-toolbar>
</template>

<script>
export default {
  name: 'ToolBar',
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Array,
      default: () => []
    },
    currentSortBy: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: ''
    },
    isFilterActive: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
