import {
  DEFAULT_FILTER_TYPES,
  defaultFilters
} from '@/compositions/filter.helpers';

export const GEOTAG_LIST_FILTER = 'GEOTAG_LIST_FILTER';

export function useGeotagFilters() {
  return [defaultFilters[DEFAULT_FILTER_TYPES.STATUS]];
}
