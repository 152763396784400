import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import router from '@/router';

import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDefault,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable,
  genMenuItemShowHistory,
  genMenuItemMove
} from '@/utils/list-generators';
import { landmarkService } from '../api';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';

export function useLandmarkMenu({ entity }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { getNextRoute } = useHistoryStack();

  const {
    id,
    enabled,
    name,
    positionAddress,
    positionRadius,
    positionCenter
  } = entity;

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () => import('@/modules/landmarks/ui/LandmarkEdit.vue'),
        props: {
          landmarkId: id
        }
      });
    }),
    genMenuItemAccess(() => {
      popup.open({
        component: () => import('@/components/popup/EditRightsGroup.vue'),
        props: {
          objectId: id
        }
      });
    }),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable landmark?',
              onConfirm: () => landmarkService.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable landmark?',
              onConfirm: () => landmarkService.enable(id)
            }
          });
        }),
    genMenuItemMove(() => {
      router.push({
        name: 'landmark_moving',
        params: {
          landmarkId: id
        }
      });
    }),
    genMenuItemDefault({
      title: 'Set coordinates sources',
      icon: '$marker_set',
      disabled: true
    }),
    genMenuItemShowHistory(async () => {
      await router.push(
        getNextRoute({
          component: componentAliases.hec,
          props: {
            bench: 30,
            ids: [positionAddress.id, positionRadius.id, positionCenter.id],
            monitoringItem: {
              name,
              id,
              type: 'propertyItem'
            }
          }
        })
      );
    }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(() => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title: 'Delete landmark?',
          onConfirm: async () => {
            await landmarkService.delete(id);
            router.push({
              name: 'landmarks'
            });
          }
        }
      });
    })
  ];
}
