import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit } from '@/utils/list-generators';

export function useDefaultItemMenu() {
  const popup = usePopup();

  return {
    genMenu: (property, callback) => {
      const { value } = property || {};
      return [
        genMenuItemEdit(() => {
          // check that property is position property
          if (value.lat !== undefined && value.lon !== undefined) {
            const { lat, lon } = value;
            popup.open({
              component: () =>
                import('@/components/popup/PopupCoordinateEdit.vue'),
              props: {
                lat,
                lon,
                hideAlt: true,
                onConfirm: value =>
                  callback({
                    lon: Number(value.lon),
                    lat: Number(value.lat)
                  })
              }
            });
          } else {
            popup.open({
              component: () => import('@/components/popup/PopupValueEdit.vue'),
              props: {
                valueName: 'Value',
                confirmText: 'Ok',
                value,
                onConfirm: value => callback(value)
              }
            });
          }
        })
      ];
    }
  };
}
