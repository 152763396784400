import { objectService } from '@/modules/common/api';
import { apiClient } from '../../../provider';
import { prepareObject } from '../../../provider/utils';
import geozoneScheme from './geozone.graphql';

export default {
  ...objectService,
  fetchGeozoneSchemaId() {
    return apiClient
      .query({
        query: geozoneScheme.fetchGeozoneSchemaId
      })
      .then(r => r.data.schemata[0]?.id || '');
  },
  async fetch(id) {
    const res = await apiClient.query({
      query: geozoneScheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    });
    return prepareObject(res.data.object);
  },
  create({ name, description, enabled, schemaId }) {
    return apiClient
      .mutate({
        mutation: geozoneScheme.create,
        variables: {
          input: {
            object: {
              name,
              description,
              enabled,
              schemaId
            }
          }
        }
      })
      .then(r => prepareObject(r.data?.createObject?.object));
  },
  update(id, { name, description }) {
    return apiClient.mutate({
      mutation: geozoneScheme.update,
      variables: {
        id,
        name,
        description
      }
    });
  }
};
