<template>
  <simple-card title="Moving landmark" @click:back="handleBack">
    <base-list :items="items" />
    <v-list>
      <v-list-item>
        <v-btn outlined block @click="handleSave">Save</v-btn>
      </v-list-item>
    </v-list>
  </simple-card>
</template>

<script>
import SimpleCard from '@/components/simple-card';
import { useLandmark } from '../../compositions/landmark';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue-demi';
import { genDefaultItem } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';
import { usePopup } from '@/compositions/popup';
import { onBeforeRouteLeave } from '@/compositions/router';
import { throttle } from 'throttle-debounce';
import { useLandmarks } from '@/modules/landmarks/compositions/landmarks';
import { objectPropertyService } from '@/modules/common/api';
import { formatNumber } from '@/utils';

export default {
  name: 'LandmarkMoving',
  components: {
    SimpleCard
  },
  props: {
    landmarkId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { landmarkId } = toRefs(props);
    const state = ref({
      radius: '',
      centerLat: '',
      centerLon: '',
      altitude: '',
      name: ''
    });
    const edited = ref(false);
    const { item: entity, load } = useLandmark(landmarkId.value);
    const { list } = useLandmarks();
    const {
      landmarks: {
        setMapToLandmarkCenter,
        selectedLandmarkIds,
        enableEditLandmark,
        disableEditLandmark,
        editLandmarksState,
        setLandmarks
      },
      markers: { clearMarkers }
    } = useMap();
    const popup = usePopup();

    onBeforeRouteLeave((to, from, next) => {
      if (edited.value) {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Save new geometry of the landmark?',
            onCancel: () => {
              setLandmarks(
                list.value.map(i => ({
                  ...i,
                  radius: i.positionRadius,
                  center: [i.positionLongitude, i.positionLatitutde]
                }))
              );
              next();
            },
            onConfirm: async () => {
              await handleSave();
              next();
            }
          }
        });
      } else {
        next();
      }
    });

    const handleBack = () => {
      root.$router.push({
        name: 'landmark_card',
        params: root.$route.params
      });
    };

    const handleSave = async () => {
      await objectPropertyService.updateValues([
        {
          id: entity.value.positionCenter.id,
          value: {
            lat: state.value.centerLat,
            lon: state.value.centerLon
          }
        },
        {
          id: entity.value.positionRadius.id,
          value:  Number(state.value.radius.toFixed(2))
        }
      ]);
      setMapToLandmarkCenter(landmarkId.value);
    };

    const items = computed(() => [
      genDefaultItem({
        title: 'Landmark name',
        subTitle: state.value.name,
        icon: '$landmark',
        invert: true
      }),
      genDefaultItem({
        title: 'Center Longitude (deg)',
        subTitle: state.value.centerLon,
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        title: 'Center Latitude (deg)',
        subTitle: state.value.centerLat,
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        title: 'Altitude',
        subTitle: state.value.altitude,
        icon: '$marker_set',
        invert: true
      }),
      genDefaultItem({
        title: 'Radius (m)',
        subTitle: formatNumber(state.value.radius, 2),
        icon: '$radius',
        invert: true
      })
    ]);

    onMounted(() => {
      load();
    });

    onBeforeUnmount(() => {
      disableEditLandmark(landmarkId.value);
    });

    const throttleUpdate = throttle(500, value => {
      state.value = {
        ...state.value,
        ...value,
        radius: value.radius
      };
    });

    watch(
      () => entity.value,
      value => {
        selectedLandmarkIds.value = [landmarkId.value];
        clearMarkers();
        setLandmarks([value]);
        setMapToLandmarkCenter(landmarkId.value);
        enableEditLandmark(landmarkId.value);
        console.log(entity.value);
        state.value = {
          name: value.name,
          altitude: value.positionAltitude?.value,
          centerLon: value.positionCenter?.value.lon,
          centerLat: value.positionCenter?.value.lat,
          radius: value.positionRadius?.value
        };
        edited.value = false;
      }
    );

    watch(
      () => editLandmarksState.value,
      value => {
        throttleUpdate(value[landmarkId.value]);
        edited.value = true;
      }
    );

    return {
      handleBack,
      items,
      handleSave,
      entity,
      editLandmarksState,
      state
    };
  }
};
</script>

<style></style>
