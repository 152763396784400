import {
  DEFAULT_FILTER_TYPES,
  defaultFilters
} from '@/compositions/filter.helpers';

export const LANDMARK_LIST_FILTER = 'LANDMARK_LIST_FILTER';
export function useLandmarkFilters() {
  return [
    defaultFilters[DEFAULT_FILTER_TYPES.STATUS],
    defaultFilters[DEFAULT_FILTER_TYPES.COLORS]
  ];
}
