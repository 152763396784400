import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import router from '@/router';

import {
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable,
  genMenuItemShowHistory
} from '@/utils/list-generators';
import { connectionService } from '../api';

export function useConnectionMenu({ entity }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { id, enabled } = entity;

  return [
    genMenuItemEdit(
      () => {
        popup.open({
          component: () =>
            import('@/modules/connections/ui/ConnectionEdit.vue'),
          props: {
            connectionId: id
          }
        });
      },
      {
        disabled: true
      }
    ),
    genMenuItemAccess(
      () => {
        popup.open({
          component: () => import('@/components/popup/EditRightsGroup.vue'),
          props: {
            objectId: id
          }
        });
      },
      {
        disabled: true
      }
    ),
    enabled
      ? genMenuItemDisable(
          () => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Disable connection?',
                onConfirm: () => connectionService.disable(id)
              }
            });
          },
          {
            disabled: true
          }
        )
      : genMenuItemEnable(
          () => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Enable connection?',
                onConfirm: () => connectionService.enable(id)
              }
            });
          },
          {
            disabled: true
          }
        ),
    genMenuItemShowHistory(undefined, { disabled: true }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(
      () => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete connection?',
            onConfirm: async () => {
              connectionService.delete(id);
              router.push({
                name: 'connections'
              });
            }
          }
        });
      },
      {
        disabled: true
      }
    )
  ];
}
