<template>
  <simple-card title="Moving base" @click:back="handleBack">
    <base-list :items="items" />
    <v-list>
      <v-list-item>
        <v-btn outlined block @click="handleSave">Save</v-btn>
      </v-list-item>
    </v-list>
  </simple-card>
</template>

<script>
import SimpleCard from '@/components/simple-card';
import { useGeotag } from '../../compositions/geotag';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue-demi';
import { genDefaultItem } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';
import { usePopup } from '@/compositions/popup';
import { onBeforeRouteLeave } from '@/compositions/router';
import { throttle } from 'throttle-debounce';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { objectPropertyService } from '@/modules/common/api';

import { useDefaultItemMenu } from './defaultItemMenu';
import { typeCast } from '@/provider/utils';
import { formatCoordinates } from '@/utils';

export default {
  name: 'GeotagMoveBase',
  components: {
    SimpleCard
  },
  props: {
    geotagId: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const { geotagId } = toRefs(props);
    const state = ref({
      name: '',
      addressCountry: '',
      addressCity: '',
      addressStreet: '',
      addressBuilding: '',
      addressRoom: '',
      addressPostcode: '',
      positionBaseAltitude: 0,
      positionBaseCoordinate: {
        lat: 0,
        lon: 0
      }
    });
    const edited = ref(false);
    const { item: entity, load } = useGeotag(geotagId.value);
    const { list } = useGeotags();
    const {
      geotags: {
        setMapToGeotagCenter,
        selectedGeotagIds,
        setGeotags,
        enableEditBasePoint,
        disableEditBasePoint,
        editBasePointState
      },
      markers: { clearMarkers }
    } = useMap();
    const popup = usePopup();

    const { genMenu: genDefaultItemMenu } = useDefaultItemMenu();

    onBeforeRouteLeave((to, from, next) => {
      if (edited.value) {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Save new geometry of the geotag?',
            onCancel: () => {
              setGeotags(list.value);
              next();
            },
            onConfirm: async () => {
              await handleSave();
              next();
            }
          }
        });
      } else {
        next();
      }
    });

    const handleBack = () => {
      root.$router.push({
        name: 'geotag_card',
        params: root.$route.params
      });
    };

    const handleSave = async () => {
      await objectPropertyService.updateValues([
        {
          id: entity.value.positionBaseCoordinate.id,
          value: typeCast(
            entity.value.positionBaseCoordinate.type,
            state.value.positionBaseCoordinate
          )
        },
        {
          id: entity.value.positionBaseAltitude.id,
          value: typeCast(
            entity.value.positionBaseAltitude.type,
            state.value.positionBaseAltitude
          )
        },
        {
          id: entity.value.addressCountry.id,
          value: typeCast(
            entity.value.addressCountry.type,
            state.value.addressCountry
          )
        },
        {
          id: entity.value.addressCity.id,
          value: typeCast(
            entity.value.addressCity.type,
            state.value.addressCity
          )
        },
        {
          id: entity.value.addressStreet.id,
          value: typeCast(
            entity.value.addressStreet.type,
            state.value.addressStreet
          )
        },
        {
          id: entity.value.addressBuilding.id,
          value: typeCast(
            entity.value.addressBuilding.type,
            state.value.addressBuilding
          )
        },
        {
          id: entity.value.addressRoom.id,
          value: typeCast(
            entity.value.addressRoom.type,
            state.value.addressRoom
          )
        },
        {
          id: entity.value.addressPostcode.id,
          value: typeCast(
            entity.value.addressPostcode.type,
            state.value.addressPostcode
          )
        }
      ]);
      setMapToGeotagCenter(geotagId.value);
    };

    const handleUpdateProperty = (stateKey, value) => {
      state.value[stateKey] = value;
      edited.value = true;
    };

    const items = computed(() => [
      genDefaultItem({
        title: 'Geotag name',
        subTitle: state.value.name,
        icon: '$geotag',
        invert: true
      }),
      genDefaultItem({
        title: 'Base Lat;Lon',
        subTitle: formatCoordinates(state.value.positionBaseCoordinate, true),
        icon: '$country',
        invert: true,
        actions: genDefaultItemMenu(
          entity.value?.positionBaseCoordinate,
          value => {
            handleUpdateProperty('positionBaseCoordinate', value);
            enableEditBasePoint(value);
          }
        )
      }),
      genDefaultItem({
        title: 'Base altitude',
        subTitle: state.value.positionBaseAltitude,
        icon: '$country',
        invert: true,
        actions: genDefaultItemMenu(
          entity.value?.positionBaseAltitude,
          value => {
            handleUpdateProperty('positionBaseAltitude', value);
          }
        )
      }),
      genDefaultItem({
        title: 'Country',
        subTitle: state.value.addressCountry,
        icon: '$country',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressCountry, value => {
          handleUpdateProperty('addressCountry', value);
        })
      }),
      genDefaultItem({
        title: 'City',
        subTitle: state.value.addressCity,
        icon: '$city',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressCity, value => {
          handleUpdateProperty('addressCity', value);
        })
      }),
      genDefaultItem({
        title: 'Street',
        subTitle: state.value.addressStreet,
        icon: '$location_city',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressStreet, value => {
          handleUpdateProperty('addressStreet', value);
        })
      }),
      genDefaultItem({
        title: 'Building',
        subTitle: state.value.addressBuilding,
        icon: '$building',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressBuilding, value => {
          handleUpdateProperty('addressBuilding', value);
        })
      }),
      genDefaultItem({
        title: 'Room',
        subTitle: state.value.addressRoom,
        icon: '$door',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressRoom, value => {
          handleUpdateProperty('addressRoom', value);
        })
      }),
      genDefaultItem({
        title: 'Address postcode',
        subTitle: state.value.addressPostcode,
        icon: '$postcode',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressPostcode, value => {
          handleUpdateProperty('addressPostcode', value);
        })
      })
    ]);

    onMounted(() => {
      load();
    });

    onBeforeUnmount(() => {
      disableEditBasePoint();
    });

    const throttleUpdate = throttle(500, value => {
      state.value = {
        ...state.value,
        positionBaseCoordinate: value
      };
    });

    watch(
      () => entity.value,
      value => {
        selectedGeotagIds.value = [geotagId.value];
        clearMarkers();
        setGeotags([entity.value]);
        setMapToGeotagCenter(geotagId.value);
        enableEditBasePoint(value.positionBaseCoordinate?.value);
        console.log(entity.value);
        state.value = {
          name: value.name,
          positionBaseAltitude: value.positionBaseAltitude?.value,
          positionBaseCoordinate: value.positionBaseCoordinate?.value,
          addressCountry: value.addressCountry?.value,
          addressCity: value.addressCity?.value,
          addressStreet: value.addressStreet?.value,
          addressBuilding: value.addressBuilding?.value,
          addressRoom: value.addressRoom?.value,
          addressPostcode: value.addressPostcode?.value
        };
        edited.value = false;
      }
    );

    watch(
      () => editBasePointState.value,
      value => {
        throttleUpdate(value);
        edited.value = true;
      }
    );

    return {
      handleBack,
      items,
      handleSave,
      entity,
      editBasePointState,
      state
    };
  }
};
</script>

<style></style>
