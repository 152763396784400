import {
  DEFAULT_FILTER_TYPES,
  defaultFilters
} from '@/compositions/filter.helpers';

export const PLAN_LIST_FILTER = 'PLAN_LIST_FILTER';

export function usePlanFilters() {
  return [defaultFilters[DEFAULT_FILTER_TYPES.STATUS]];
}
