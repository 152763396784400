import { useObjects } from '@/modules/objects/compositions/objects';
import { computed, ref } from '@vue/composition-api';
import { useLandmarks } from '@/modules/landmarks/compositions/landmarks';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useGeozones } from '@/modules/geozones/compositions/geozones';
import { usePlans } from '@/modules/plans/compositions/plans';
import { useTypes } from '@/modules/object-types/compositions/types';
import { useConnections } from '@/modules/connections/compositions/connections';

export const SearchEntityEnum = {
  object: 'object',
  landmark: 'landmark',
  geozone: 'geozone',
  geotag: 'geotag',
  plan: 'plan',
  type: 'type',
  connection: 'connection'
};
export const EntityIconEnum = {
  object: '$object',
  landmark: '$landmark',
  geozone: '$geozone',
  geotag: '$geotag',
  plan: '$plan',
  type: '$object',
  connection: '$connection'
};

export const EntityPathEnum = {
  object: id => `/objects/list/${id}`,
  landmark: id => `/landmarks/${id}`,
  geozone: id => `/geozones/${id}`,
  geotag: id => `/geotags/${id}`,
  plan: id => `/settings/plans/${id}`,
  type: id => `/settings/types/${id}`,
  connection: id => `/settings/connections/${id}`
};

export function useGlobalSearch() {
  const query = ref('');
  const entityKey = ref('');

  const { resultList: objectList } = useObjects();
  const { resultList: landmarkList } = useLandmarks();
  const { list: geotagList } = useGeotags();
  const { resultList: geozoneList } = useGeozones();
  const { resultList: planList } = usePlans();
  const { list: typeList } = useTypes();
  const { list: connectionList } = useConnections();

  const listMap = {
    [SearchEntityEnum.object]: objectList,
    [SearchEntityEnum.landmark]: landmarkList,
    [SearchEntityEnum.geozone]: geozoneList,
    [SearchEntityEnum.geotag]: geotagList,
    [SearchEntityEnum.plan]: planList,
    [SearchEntityEnum.type]: typeList,
    [SearchEntityEnum.connection]: connectionList
  };

  const getFilteredList = (list, query, entityKey) => {
    if (!query) return [];
    return list
      .filter(
        item =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.id.toLowerCase().includes(query.toLowerCase())
      )
      .map(item => ({
        id: item.id,
        name: item.name,
        icon: EntityIconEnum[entityKey],
        to: EntityPathEnum[entityKey](item.id)
      }));
  };

  const entitySearchList = computed(() =>
    getFilteredList(
      listMap[entityKey.value].value,
      query.value,
      entityKey.value
    )
  );

  const commonSearchList = computed(() => {
    return Object.entries(listMap).reduce((acc, [key, list]) => {
      if (key === entityKey.value) return acc;
      return acc.concat(getFilteredList(list.value, query.value, key));
    }, []);
  });

  return {
    query,
    entityKey,
    entitySearchList,
    commonSearchList
  };
}
