import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import router from '@/router';

import {
  genMenuItemAdd,
  genMenuItemAccess,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEdit,
  genMenuItemEnable,
  genMenuItemShowHistory,
  genMenuItemMove,
  genMenuItemChangeForm
} from '@/utils/list-generators';
import { geotagservice } from '../api';

export function useGeotagMenu({ entity }) {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { id, enabled } = entity;

  return [
    genMenuItemEdit(() => {
      popup.open({
        component: () => import('@/modules/geotags/ui/GeotagEdit.vue'),
        props: {
          geotagId: id
        }
      });
    }),
    genMenuItemAccess(() => {
      popup.open({
        component: () => import('@/components/popup/EditRightsGroup.vue'),
        props: {
          objectId: id
        }
      });
    }),
    genMenuItemAdd(
      () => {
        popup.open({
          component: () => import('@/modules/geotags/ui/GeotagCopy.vue'),
          props: {
            geotagId: id,
            copy: true
          }
        });
      },
      {
        title: 'Create copy'
      }
    ),
    enabled
      ? genMenuItemDisable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Disable geotag?',
              onConfirm: () => geotagservice.disable(id)
            }
          });
        })
      : genMenuItemEnable(() => {
          popup.openConfirm({
            component: () =>
              import('@/components/popup/PopupConfirmAction.vue'),
            props: {
              title: 'Enable geotag?',
              onConfirm: () => geotagservice.enable(id)
            }
          });
        }),
    genMenuItemChangeForm(() => {
      router.push({
        name: 'geotag_change_form',
        params: {
          geotagId: id
        }
      });
    }),
    genMenuItemMove(
      () => {
        router.push({
          name: 'geotag_move_base',
          params: {
            geotagId: id
          }
        });
      },
      {
        title: 'Move base'
      }
    ),
    genMenuItemShowHistory(undefined, { disabled: true }),
    genMenuItemCopy(async () => copyWithAlert(id), {
      title: 'Copy UUID'
    }),
    genMenuItemDelete(() => {
      popup.openConfirm({
        component: () => import('@/components/popup/PopupConfirmAction.vue'),
        props: {
          title: 'Delete geotag?',
          onConfirm: async () => {
            geotagservice.delete(id);
            router.push({
              name: 'geotags'
            });
          }
        }
      });
    })
  ];
}
