import { genMenuItemDefault } from '@/utils/list-generators';

export default [
  // genMenuItemDefault({
  //   id: 'add',
  //   icon: '$add',
  //   title: 'Add new',
  //   disabled: true
  // }),
  // genMenuItemDefault({
  //   id: 'add from template',
  //   icon: '$add',
  //   title: 'Add from template',
  //   disabled: true
  // }),
  // genMenuItemDefault({
  //   id: 'import',
  //   icon: '$import',
  //   title: 'Import',
  //   disabled: true
  // })
];
