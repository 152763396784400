import linkedObjectSchema from '../api/linked-object-list.graphql';
import { apiClient } from '@/provider';

export default {
  async fetch(objectId) {
    const res = await apiClient.query({
      query: linkedObjectSchema.fetch,
      variables: {
        objectId
      },
      fetchPolicy: 'no-cache'
    });
    return res.data.objectsToObjects.map(i => i.object2);
  },
  async fetchAll() {
    const res = await apiClient.query({
      query: linkedObjectSchema.fetchAll,
      fetchPolicy: 'no-cache'
    });
    return res?.data?.objects;
  },
  async fetchAllShort(schemaType, schemaId) {
    const res = await apiClient.query({
      query: linkedObjectSchema.fetchAllShort,
      variables: {
        filter: {
          schemaType: {
            in: [schemaType]
          },
          ...(schemaId ? { schemaId: { equalTo: schemaId } } : {})
        }
      },
      fetchPolicy: 'no-cache'
    });
    return res?.data?.objects;
  }
};
