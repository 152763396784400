import { usePopup } from '@/compositions/popup';
import { genMenuItemAdd, genMenuItemDefault } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';

export function useObjectListActions() {
  const popup = usePopup();
  const {
    geotags: { getDefaultSize },
    getCenter
  } = useMap();

  return {
    listActions: [
      genMenuItemAdd(() => {
        popup.open({
          component: () => import('@/modules/geotags/ui/GeotagEdit.vue'),
          props: {
            defaultSize: getDefaultSize(),
            defaultCenter: getCenter()
          }
        });
      }),
      genMenuItemDefault({
        id: 'import',
        icon: '$import',
        title: 'Import',
        disabled: true
      })
    ]
  };
}
