import { convertDate } from '@/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { computed, onMounted, ref, watch } from '@vue/composition-api';
import notificationsScheme from '../api/notifications.graphql';
import { genShowMoreItem, COMPONENTS } from '@/utils/list-generators';
import { useAuth } from '@/modules/auth/api';
import { ColorNames, colors } from '@/compositions/map/utils/data';

import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { getObjectPropertyByKey } from '@/provider/utils';
import { usePopup } from '@/compositions/popup';

export const NOTIFICATION_TYPES = {
  NOTICE: 'NOTICE',
  ALERT: 'ALERT',
  ALERT_TRIGGERED: 'ALERT_TRIGGERED',
  MESSAGE: 'MESSAGE',
  ALARM: 'ALARM',
  TRIGGERED: 'TRIGGERED',
  DISMISSED: 'DISMISSED'
};

export const NOTIFICATION_TAGS = {
  [NOTIFICATION_TYPES.MESSAGE]: ['message'],
  [NOTIFICATION_TYPES.NOTICE]: ['notice'],
  [NOTIFICATION_TYPES.ALERT]: ['alert'],
  [NOTIFICATION_TYPES.ALERT_TRIGGERED]: ['alert', 'triggered'],
  [NOTIFICATION_TYPES.ALARM]: ['alarm'],
  [NOTIFICATION_TYPES.TRIGGERED]: ['triggered'],
  [NOTIFICATION_TYPES.DISMISSED]: ['dismissed']
};

export const NOTIFICATION_TYPES_TITLES = {
  [NOTIFICATION_TYPES.NOTICE]: 'Notice',
  [NOTIFICATION_TYPES.ALERT]: 'Alert',
  [NOTIFICATION_TYPES.ALERT_TRIGGERED]: 'Alert triggered',
  [NOTIFICATION_TYPES.MESSAGE]: 'Message'
};

export const NOTIFICATION_ICONS = {
  [NOTIFICATION_TYPES.NOTICE]: '$notice',
  [NOTIFICATION_TYPES.ALERT]: '$alert_on',
  [NOTIFICATION_TYPES.ALERT_TRIGGERED]: '$alert_on',
  [NOTIFICATION_TYPES.MESSAGE]: '$message'
};

export function getNotificationType(notificationTags = []) {
  if (notificationTags.includes('alert')) {
    if (notificationTags.includes('triggered')) {
      return NOTIFICATION_TYPES.ALERT_TRIGGERED;
    }
    return NOTIFICATION_TYPES.ALERT;
  } else if (notificationTags.includes('message')) {
    return NOTIFICATION_TYPES.MESSAGE;
  } else {
    return NOTIFICATION_TYPES.NOTICE;
  }
}

const RECENTLY_NOTIFICATION_COUNT = 10;

export function useNotifications(objectId) {
  const { getNextRoute } = useHistoryStack();
  const { user, programName } = useAuth();

  const notificationFilter = ref({});
  const notificationSort = ref({});
  const notificationQuery = ref('');
  const after = ref(undefined);
  const hasNew = ref(false);
  const popup = usePopup();

  const genNotificationItem = item => {
    const { message, description, type } = item;
    return {
      type: COMPONENTS.ListItem,
      props: {
        title: message,
        subTitle: description,
        icon: NOTIFICATION_ICONS[type],
        iconColor:
          type === NOTIFICATION_TYPES.ALERT_TRIGGERED ? 'red' : undefined
      },
      listeners: {
        click: () => {
          popup.open({
            component: () =>
              import(
                '@/modules/notifications/ui/notifications-list/NotificationInfo'
              ),
            props: {
              item
            }
          });
        }
      }
    };
  };

  const variables = computed(() => {
    let filter = {
      user: {
        equalTo: user.value?.id
      },
      deliveryPath: {
        equalTo: programName.value
      },
      ...notificationFilter.value
    };

    if (objectId) {
      filter = {
        ...filter,
        notification: {
          objectId: {
            equalTo: objectId
          }
        }
      };
    }

    if (notificationQuery.value) {
      const queryFilter = {
        or: [
          {
            message: {
              includes: notificationQuery.value
            }
          },
          {
            notification: {
              object: {
                name: {
                  includes: notificationQuery.value
                }
              }
            }
          }
          // {
          //   notification: {
          //     object: {
          //       id: {
          //         equalTo: notificationQuery.value
          //       }
          //     }
          //   }
          // }
        ]
      };
      filter = {
        and: [filter, queryFilter]
      };
    }
    console.log({
      filter,
      ...notificationSort.value,
      ...(after.value ? { after } : {})
    });
    return {
      filter,
      ...notificationSort.value,
      ...(after.value ? { after } : {})
    };
  });

  const { result, load, loading, subscribeToMore, onResult } = useLazyQuery(
    notificationsScheme.fetchNotificationsDeliveries,
    variables,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network'
    }
  );

  const notificationDeliveriesConnection = useResult(
    result,
    {
      edges: [],
      pageInfo: {},
      totalCount: 0
    },
    data => data.notificationDeliveriesConnection
  );

  watch(
    () => notificationFilter.value,
    () => {
      after.value = undefined;
    }
  );

  watch(
    () => notificationSort.value,
    () => {
      after.value = undefined;
    }
  );

  watch(
    () => notificationQuery.value,
    () => {
      after.value = undefined;
    }
  );

  const loadMore = async state => {
    if (notificationDeliveriesConnection.value.pageInfo.hasNextPage) {
      after.value = notificationDeliveriesConnection.value.pageInfo.endCursor;
    } else {
      state?.complete();
    }
    onResult(() => state?.loaded());
  };

  subscribeToMore({
    document: notificationsScheme.listenNotifications,
    variables: {
      deliveryUser: user.id,
      deliveryPath: programName
    }
  });

  const totalCount = computed(() => {
    return notificationDeliveriesConnection.value.totalCount;
  });

  const items = computed(() => {
    return notificationDeliveriesConnection.value.edges.map(
      ({ node: { notification: i } }) => {
        const currentStateIcon = getObjectPropertyByKey(
          i.object,
          'currentStateIcon'
        )?.value;
        const currentStateColor =
          getObjectPropertyByKey(i.object, 'currentStateColor')?.value ||
          colors[ColorNames.default];
        const type = getNotificationType(i.tags);
        return {
          id: i.id,
          notification: i,
          objectId: i.object?.id,
          message: i.message,
          objectName: i.object?.name || 'DELETED OBJECT',
          description: `${convertDate(i.createdAt)} by ${i.userByBy.mName ||
            i.userByBy.login}`,
          type,
          typeIcon: NOTIFICATION_ICONS[type],
          typeIconColor:
            type === NOTIFICATION_TYPES.ALERT_TRIGGERED ? 'red' : undefined,
          currentStateColor,
          currentStateIcon
        };
      }
    );
  });

  const listItems = computed(() =>
    items.value.map(i => genNotificationItem(i))
  );

  const recentlyItems = computed(() => [
    ...listItems.value.slice(0, RECENTLY_NOTIFICATION_COUNT),
    ...(notificationDeliveriesConnection.value.totalCount >
    RECENTLY_NOTIFICATION_COUNT
      ? [
          genShowMoreItem({
            to: getNextRoute({
              component: componentAliases.hc,
              props: {
                objectId
              }
            })
          })
        ]
      : [])
  ]);

  onMounted(() => {
    if (localStorage.getItem('pixelHasNew') === '0') {
      hasNew.value = false;
    }
  });

  return {
    items,
    listItems,
    recentlyItems,
    loadMore,
    load,
    loading,
    result,
    hasNew,
    notificationFilter,
    notificationSort,
    notificationQuery,
    totalCount
  };
}
