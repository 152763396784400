export default [
  {
    id: 'new_first',
    title: 'New ones first',
    query: {
      orderBy: 'CREATED_AT_DESC'
    }
  },
  {
    id: 'old_first',
    title: 'Old ones first',
    query: {
      orderBy: 'CREATED_AT_ASC'
    }
  }
  // {
  //   id: 'by_objects',
  //   title: 'By objects'
  // }
];
