import { sortTypes } from '@/compositions/sortBy';

export default [
  {
    id: 'name',
    sortType: sortTypes.abc,
    sortKey: 'name',
    title: 'Name'
  },
  {
    id: 'status',
    sortType: sortTypes.boolean,
    sortKey: 'enabled',
    title: 'Status'
  }
];
