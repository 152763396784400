import { computed, ref } from '@vue/composition-api';
import isEqual from 'lodash.isequal';
import { getDefaultFilter } from '@/compositions/filter.helpers';
import { usePopup } from '@/compositions/popup';

export function useFilter(items, filterDefinition, storageKey) {
  const popup = usePopup();

  const currentFilter = ref({});

  const setFilter = filter => {
    currentFilter.value = filter;
    if (storageKey) {
      localStorage.setItem(storageKey, JSON.stringify(currentFilter.value));
    }
  };
  const allowFilters = item => {
    return filterDefinition.every(filter =>
      filter.filterFunc(item, (currentFilter.value || {})[filter.id])
    );
  };

  const isFilterActive = computed(
    () => !isEqual(currentFilter.value, getDefaultFilter(filterDefinition))
  );

  const filteredItems = computed(() => {
    return items.value.filter(item => allowFilters(item));
  });

  const handleFilterOpen = () => {
    popup.open({
      component: () => import('@/components/popup/PopupFilter.vue'),
      props: {
        filters: filterDefinition,
        value: currentFilter.value,
        onConfirm: filters => {
          setFilter(filters);
        }
      }
    });
  };

  if (storageKey) {
    const filter = JSON.parse(localStorage.getItem(storageKey));
    if (filter) {
      setFilter(filter);
    } else {
      setFilter(getDefaultFilter(filterDefinition));
    }
  }

  return {
    setFilter,
    currentFilter,
    isFilterActive,
    filteredItems,
    handleFilterOpen
  };
}
