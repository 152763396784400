import { usePopup } from '@/compositions/popup';
import { genMenuItemAdd } from '@/utils/list-generators';
import { useMap } from '@/compositions/map';

export function useObjectListActions() {
  const popup = usePopup();
  const {
    plans: { getDefaultCenter }
  } = useMap();

  return {
    listActions: [
      genMenuItemAdd(() => {
        popup.open({
          component: () => import('@/modules/plans/ui/PlanEdit.vue'),
          props: {
            defaultLonLat: getDefaultCenter()
          }
        });
      })
    ]
  };
}
