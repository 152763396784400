import { convertDate } from '@/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { computed, onMounted, ref, watch } from '@vue/composition-api';
import notificationsScheme from '../api/notifications.graphql';
import { useAuth } from '@/modules/auth/api';
import {
  getNotificationType,
  NOTIFICATION_ICONS,
  NOTIFICATION_TYPES
} from '@/modules/notifications/compositions/notifications';
import { getObjectPropertyByKey, SUB_EVENT_TYPES } from '@/provider/utils';
import router from '@/router';
import { COMPONENTS } from '@/utils/list-generators';
import schema from '@/modules/account/schema.graphql';
import { useGeotags } from '@/modules/geotags/compositions/geotags';

const hasNew = ref(false);
export function useNotificationsCurrentUser() {
  const { programName, user, getUserProfileId } = useAuth();
  const { setGeotag } = useGeotags();
  const audio = new Audio('/notify.mp3');

  const variables = ref({
    orderBy: 'CREATED_AT_DESC',
    filter: {
      user: {
        equalTo: ''
      },
      deliveryPath: {
        equalTo: programName.value
      }
    }
  });

  const {
    result,
    load,
    loading,
    fetchMore,
    subscribeToMore,
    restart
  } = useLazyQuery(
    notificationsScheme.fetchNotificationsDeliveries,
    variables.value,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const notificationsConnections = useResult(
    result,
    {
      edges: [],
      pageInfo: {},
      totalCount: 0
    },
    data => data.notificationDeliveriesConnection
  );

  const loadProfile = useLazyQuery(
    schema.loadProfile,
    {
      id: null
    },
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );

  const loadMore = async state => {
    const result = await fetchMore({
      variables: {
        after: notificationsConnections.value.pageInfo.endCursor
      }
    });

    if (result.data.notificationDeliveriesConnection.pageInfo.hasNextPage) {
      state?.loaded();
    } else {
      state?.complete();
    }
  };

  const listItems = computed(() =>
    notificationsConnections.value.edges.map(
      ({ node: { notification: i } }) => {
        const type = getNotificationType(i.tags);
        return {
          type: COMPONENTS.ListItem,
          props: {
            title: i.message,
            subTitle: `${convertDate(i.createdAt)} by ${i.userByBy.mName ||
              i.userByBy.login}`,
            icon: NOTIFICATION_ICONS[type],
            iconColor:
              type === NOTIFICATION_TYPES.ALERT_TRIGGERED ? 'red' : undefined,
            type
          },
          listeners: {
            click: () => {
              const objectId = i.object?.id;
              const geotagId = getObjectPropertyByKey(
                i.object,
                'positionGeotagId'
              );
              if (!objectId) return;
              router.push({
                name: 'object_card',
                params: { ...router.currentRoute.params, objectId }
              });
              setGeotag(geotagId.value, true);
            }
          }
        };
      }
    )
  );

  watch(
    () => user.value,
    () => {
      if (user.value?.id) {
        variables.value.filter.user.equalTo = user.value.id;
        load();

        subscribeToMore({
          document: notificationsScheme.listenNotifications,
          variables: {
            orderBy: 'CREATED_AT_DESC',
            deliveryUser: user.value?.id,
            deliveryPath: programName.value
          },
          updateQuery: (previousResult, { subscriptionData }) => {
            const notificationsSoundInTheApp = loadProfile.result.value?.object.objectProperties.find(
              item => item.key === 'notificationsSoundInTheApp'
            ) || { value: false };
            const relatedNode =
              subscriptionData.data?.Notifications?.relatedNode;
            const eventType = subscriptionData.data?.Notifications?.event;
            if (eventType !== SUB_EVENT_TYPES.insert) return;
            if (relatedNode.notification) {
              hasNew.value = true;
              if (notificationsSoundInTheApp.value) {
                audio.play();
              }
              return {
                notificationDeliveriesConnection: {
                  edges: [
                    { node: relatedNode },
                    ...previousResult.notificationDeliveriesConnection.edges
                  ],
                  pageInfo:
                    previousResult.notificationDeliveriesConnection.pageInfo,
                  totalCount:
                    previousResult.notificationDeliveriesConnection.totalCount +
                    1
                }
              };
            }
            return previousResult;
          }
        });
      }
    },
    {
      immediate: true
    }
  );

  onMounted(() => {
    if (localStorage.getItem('pixelHasNew') === '0') {
      hasNew.value = false;
    }
    getUserProfileId().then(id => {
      loadProfile.variables.value.id = id;
      loadProfile.load();
    });
  });

  return {
    listItems,
    loadMore,
    load,
    loading,
    result,
    hasNew,
    restart,
    variables,
    notificationsConnections
  };
}
