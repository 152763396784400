import { usePopup } from '@/compositions/popup';
import { genMenuItemAdd } from '@/utils/list-generators';
import { useMap } from '../../../../compositions/map';

export function useObjectListActions() {
  const popup = usePopup();

  const {
    landmarks: { getDefaultCenter }
  } = useMap();

  return {
    listActions: [
      genMenuItemAdd(() => {
        popup.open({
          component: () => import('@/modules/landmarks/ui/LandmarkEdit.vue'),
          props: {
            defaultLonLat: getDefaultCenter()
          }
        });
      })
    ]
  };
}
