<template>
  <div class="simple-card d-flex flex-column">
    <v-toolbar dense class="flex-grow-0">
      <v-btn icon @click="$emit('click:back')">
        <v-icon>$back</v-icon>
      </v-btn>
      <v-toolbar-title class="font-weight-medium">
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SimpleCard',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="sass" scoped>
.simple-card
  width: inherit
  height: inherit
</style>
