import { apiClient } from '../../../provider';
import landmarksSchema from './landmark-list.graphql';

export default {
  async fetch() {
    const res = await apiClient.query({
      query: landmarksSchema.load,
      fetchPolicy: 'no-cache'
    });
    return res.data?.objects;
  }
};
