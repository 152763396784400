import geotagschema from '../api/geotag.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { prepareObject } from '@/provider/utils';
import { mediaClient } from '@/provider';

export function useGeotag(geotagId) {
  console.log('useGeotag');
  const { result, load, loading } = useLazyQuery(
    geotagschema.fetch,
    {
      id: geotagId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const item = useResult(result, {}, data => {
    const preparedObject = prepareObject(data.object);
    return {
      ...preparedObject,
      points: preparedObject.positionPoints.value,
      url: mediaClient.getImageUrl(preparedObject.infoImageId.value)
    };
  });

  return {
    load,
    item,
    loading
  };
}
