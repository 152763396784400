<template>
  <list-item-selectable v-bind="$attrs" two-line v-on="$listeners">
    <template #item:avatar>
      <v-list-item-avatar :color="colors['default']">
        <v-icon color="white" v-text="'$plan'" />
      </v-list-item-avatar>
    </template>
    <template #item:content>
      <v-list-item-title>
        {{ item.name }}
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex align-center">
        <div class="mr-2" :class="{ 'red--text': !item.enabled }">
          {{ item.enabled ? 'Enabled' : 'Disabled' }}
        </div>
      </v-list-item-subtitle>
    </template>
    <template #item:actions>
      <v-list-item-action>
        <dropdown-menu :items="actions" item-key-text="name">
          <template #activator="{on}">
            <v-btn icon @click.stop.prevent="on.click" @mousedown.stop="">
              <v-icon v-text="'$more_vert'" />
            </v-btn>
          </template>
        </dropdown-menu>
      </v-list-item-action>
    </template>
  </list-item-selectable>
</template>

<script>
import { colors } from '@/compositions/map/utils/data';
export default {
  name: 'ObjectListItem',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {
      colors
    };
  }
};
</script>

<style></style>
