import { apiClient } from '../../../provider';
import iconsScheme from './icon-list.graphql';

export default {
  async fetch(id) {
    const res = await apiClient.query({
      query: iconsScheme.fetch,
      fetchPolicy: 'no-cache'
    });
    return res.data.objects;
  }
};
