import { objectService } from '@/modules/common/api';
import { apiClient } from '@/provider';
import { prepareObject } from '@/provider/utils';
import forwardingRuleScheme from './forwarding-rule.graphql';

export default {
  ...objectService,
  fetchSchemaId() {
    return apiClient
      .query({
        query: forwardingRuleScheme.fetchForwardingRuleSchemaId
      })
      .then(r => r.data.schemata[0]?.id || '');
  },
  async fetch(id) {
    const res = await apiClient.query({
      query: forwardingRuleScheme.fetch,
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    });
    return prepareObject(res.data.object);
  },
  create({ name, description = '', enabled = true, schemaId }) {
    return apiClient
      .mutate({
        mutation: forwardingRuleScheme.create,
        variables: {
          input: {
            object: {
              name,
              description,
              enabled,
              schemaId
            }
          }
        }
      })
      .then(r => prepareObject(r.data?.createObject?.object));
  },
  update(id, { name, description = '' }) {
    return apiClient.mutate({
      mutation: forwardingRuleScheme.update,
      variables: {
        id,
        name,
        description
      }
    });
  }
};
